import {Platform} from 'react-native';
import {createNavigationContainerRef} from '@react-navigation/native';
import {StackActions} from '@react-navigation/native';

export const navigationRef = createNavigationContainerRef();

const isReady = () => navigationRef.isReady();

export function navigate(name, params) {
  isReady() && navigationRef.navigate(name, params);
}

export function replace(name, params) {
  isReady() && navigationRef.dispatch(StackActions.replace(name, params));
}

export function navGoBack() {
  if (
    Platform.OS === 'web' &&
    navigationRef.current.getState().routes.length < 2
  ) {
    window.history.back();
  } else {
    navigationRef.goBack();
  }
}
// 优化一版goback 逻辑
export function smartGoBack() {
  if (!navigationRef.current) {
    return;
  }

  const whiteList = ['Login', 'SignUp', 'BindPhone'];
  // const whiteParams = ['Live'];
  const state = navigationRef.current.getState();
  const currentIndex = state.index;
  const routes = state.routes;
  console.log(routes, 'routes');

  // web平台特殊处理
  if (Platform.OS === 'web' && routes.length < 2) {
    // 如果是Index ,则不做操作
    if (
      routes?.[currentIndex]?.name === 'Index' &&
      routes?.[currentIndex]?.params?.screen === 'Live'
    ) {
      return;
    }
    window.history.back();
    return;
  }

  // 查找第一个不在白名单中的路由
  let targetIndex = currentIndex - 1;
  while (targetIndex >= 0 && whiteList.includes(routes[targetIndex].name)) {
    targetIndex--;
  }

  // 根据查找结果处理导航
  if (targetIndex >= 0) {
    // 找到了有效的目标路由
    const targetRoute = routes[targetIndex];
    navigationRef.current.navigate(targetRoute.name, targetRoute.params);
  } else {
    // 没找到有效路由，返回首页
    // navigationRef.current.navigate('Home');
    // 没找到有效路由，返回首页（假设Home在Main导航器中）
    navigationRef.current.navigate('Index', {
      screen: 'Home',
    });
  }
}
